import axios from 'axios';
import { Api, AppConfig } from '../config/config';
import {
    AssessmentGuide,
    AssessmentQuestion,
    AssessmentResult,
    BranchResolutionSteps,
    ISecurityAssessmentSummary,
    SecurityArticle,
} from '../config/interface';
import { getAuthToken } from '../entities/session';

type ExecuteMethods = 'GET' | 'POST' | 'PUT' | 'DELETE';
const execute = async <Response = any>(
    method: ExecuteMethods,
    url,
    params?,
    isSecured = false
) => {
    let options: any = {};
    const token = getAuthToken()?.replaceAll('"', '');

    if (isSecured) {
        options.headers = {
            'x-security-advisor-authtoken': token,
        };
    }

    switch (method.toUpperCase()) {
        case 'GET':
            return axios.get<Response>(url, options);
        case 'POST':
            return axios.post<Response>(url, params?.data, options);
        case 'PUT':
            return axios.put<Response>(url, params?.data, options);
        case 'DELETE':
            return axios.delete<Response>(url, options);
        default:
            return axios.get<Response>(url, options);
    }
};

export const registerPrimaryUser = async ({
    mdn,
    email,
    firstName,
    lastName,
}: {
    mdn: string;
    email: string;
    firstName: string;
    lastName: string;
}) => {
    if (mdn && email) {
        const { data } = await execute('POST', Api.REGISTER(), {
            data: {
                email,
                carrier: AppConfig.carrier,
                program: AppConfig.program,
                mdn,
                firstName,
                lastName,
            },
        });
        return data;
    } else {
        return null;
    }
};

export const updatePrimaryUser = async ({
    mdn,
    email,
}: {
    mdn: string;
    email: string;
}) => {
    if (email) {
        const { data } = await execute('POST', Api.REGISTER(), {
            data: {
                email,
                carrier: AppConfig.carrier?.toLowerCase(),
                program: AppConfig.program?.toLowerCase(),
                mdn: mdn || '',
            },
        });
        return data;
    } else {
        return null;
    }
};

export const verifyRegisteredMobileNumber = async ({ mdn }: { mdn: string }) => {
    if (mdn) {
        const { data } = await execute('POST', Api.VERIFY_MOBILE_NUMBER(), {
            data: {
                carrier: AppConfig.carrier,
                program: AppConfig.program,
                mdn,
            },
        });
        return data;
    } else {
        return null;
    }
};

export const verifySmsOtp = async ({ mdn, otp }: { mdn: string; otp: string }) => {
    if (mdn) {
        const { data } = await execute('POST', Api.VERIFY_SMS_OTP(), {
            data: {
                carrier: AppConfig.carrier,
                program: AppConfig.program,
                mdn,
                otp,
            },
        });
        return data;
    } else {
        return null;
    }
};

export const login = async (email: string) => {
    if (email) {
        const { data } = await execute('POST', Api.LOGIN, {
            data: { email, carrier: AppConfig.carrier, program: AppConfig.program },
        });
        return data;
    } else {
        return null;
    }
};

export const logout = async () => {
    const { data } = await execute('GET', Api.LOGOUT(), {}, true);
    return data;
};

export const verifyOtp = async ({ otp, email }) => {
    if (otp) {
        const { data } = await execute('POST', Api.VERIFY_OTP, {
            data: { otp, email, carrier: AppConfig.carrier, program: AppConfig.program },
        });
        return data;
    } else {
        return null;
    }
};

export const verifyRegistrationEmailOtp = async ({ otp, email }) => {
    if (otp) {
        const { data } = await execute('POST', Api.VERIFY_REGISTRATION_EMAIL_OTP(), {
            data: { otp, email, carrier: AppConfig.carrier, program: AppConfig.program },
        });
        return data;
    } else {
        return null;
    }
};

export const fetchAccountDetails = async ({ email }: { email: string }) => {
    if (email) {
        const { data } = await execute('GET', Api.GET_ACCOUNT_DETAILS(), {}, true);
        return data;
    } else {
        return null;
    }
};

export const fetchAllAssets = async ({
    email,
    rescan = false,
}: {
    email: string;
    rescan?: boolean;
}) => {
    if (email) {
        const { data } = await execute(
            'POST',
            Api.GET_ASSETS({ rescan }),
            { data: { email } },
            true
        );
        return data;
    } else {
        return null;
    }
};

export const fetchAssetByType = async ({
    email,
    asset,
}: {
    email: string;
    asset: string;
}) => {
    if (email && asset) {
        const { data } = await execute(
            'POST',
            Api.GET_ASSET_BY_TYPE({ asset }),
            { data: { email } },
            true
        );
        return data;
    } else {
        return null;
    }
};

export const fetchAssetByTypeGroupBy = async ({
    email,
    asset,
    groupby = 'value',
}: {
    email: string;
    asset: string;
    groupby: string;
}) => {
    if (email && asset) {
        const { data } = await execute(
            'POST',
            Api.GET_ASSET_BY_TYPE_GROUPBY({ asset, groupby }),
            { data: { email } },
            true
        );
        return data;
    } else {
        return null;
    }
};

export const unmaskedValue = async ({
    email,
    asset,
    documentId,
}: {
    email: string;
    asset: string;
    documentId: number;
}) => {
    if (email && asset && documentId) {
        const { data } = await execute(
            'POST',
            Api.UNMASKED_VALUE({ asset, documentId }),
            { data: { email } },
            true
        );
        return data;
    } else {
        return null;
    }
};

export const markedResolve = async ({
    email,
    asset,
    documentId,
}: {
    email: string;
    asset: string;
    documentId: number;
}) => {
    if (email && asset && documentId) {
        const { data } = await execute(
            'PUT',
            Api.MARKED_RESOLVE({ asset, documentId }),
            { data: { resolved: true, email } },
            true
        );
        return data;
    } else {
        return null;
    }
};

export const fetchAssessmentsResultSummary = async ({
    email,
    isSummary = true,
}: {
    email: string;
    isSummary?: boolean;
}) => {
    if (email) {
        const url = isSummary ? Api.GET_ASSESSMENTS_SUMMARY() : '';

        const { data } = await execute<ISecurityAssessmentSummary>('GET', url, {}, true);

        return data;
    } else {
        return null;
    }
};

export const fetchAssessmentResult = async (email: string) => {
    const { data } = await execute<AssessmentResult>(
        'GET',
        Api.GET_ASSESSMENTS_RESULT(),
        undefined,
        true
    );

    return data;
};

export const fetchAssessmentQuestions = async () => {
    const { data } = await execute<AssessmentQuestion[]>(
        'GET',
        Api.GET_ASSESSMENT_QUESTIONS,
        undefined,
        true
    );

    return data;
};

export const postAssessmentResult = async ({
    email,
    answers,
}: {
    email: string;
    answers: string[];
}) => {
    if (email) {
        const { data } = await execute<AssessmentResult>(
            'POST',
            Api.GET_ASSESSMENTS_RESULT(),
            {
                data: { answers },
            },
            true
        );
        return data;
    } else {
        return null;
    }
};

export const fetchAssessmentGuide = async (guide: string) => {
    const { data } = await execute<AssessmentGuide>(
        'GET',
        Api.GET_ASSESSMENTS_GUIDE(decodeURIComponent(guide)),
        undefined,
        true
    );

    return data;
};

export const resolveAssessment = async ({
    email,
    category,
    guide,
}: {
    email: string;
    category: string;
    guide: string;
}) => {
    const { data } = await execute<{ message: string }>(
        'PUT',
        Api.RESOLVE_ASSESSMENT(),
        {
            data: {
                category,
                guide,
            },
        },
        true
    );

    return data;
};

export const fetchBreachResolutionSteps = async (asset: string) => {
    const { data } = await execute<BranchResolutionSteps>(
        'GET',
        Api.GET_BREACH_RESOLUTION_STEPS(asset),
        {},
        true
    );

    return data;
};

export const fetchDashboardArticles = async () => {
    const { data } = await execute('GET', Api.GET_DASHBOARD_ARTICLES(), {}, true);

    return data;
};

export const fetchSecurityArticles = async ({ topic }: { topic?: string }) => {
    let t = !topic || topic === 'all' ? '' : topic;
    const { data } = await execute<SecurityArticle[]>(
        'GET',
        Api.GET_ALL_SECURITY_ARTICLES({
            topic: t,
        }),
        {},
        true
    );
    return data;
};

export const fetchSecurityArticle = async (slug: string) => {
    const { data } = await execute<SecurityArticle>(
        'GET',
        Api.GET_SECURITY_ARTICLE({ slug }),
        {},
        true
    );
    return data;
};

export const fetchLatestArticles = async () => {
    const { data } = await execute('GET', Api.GET_ALL_LATEST_ARTICLES(), {}, true);
    return data;
};

export const fetchArticleTopics = async () => {
    const { data } = await execute<string[]>('GET', Api.GET_ARTICLE_FILTERS(), {}, true);
    return data;
};

export const fetchProfile = async ({ email }: { email: string }) => {
    const { data } = await execute('GET', Api.GET_PROFILE(), {}, true);
    return data;
};

export const updateProfile = async ({
    email,
    fields,
}: {
    email: string;
    fields: any;
}) => {
    const { data } = await execute(
        'PUT',
        Api.UPDATE_PROFILE(),
        { data: { ...fields, email } },
        true
    );
    return data;
};

export const deleteSecondaryEmail = async ({ email }: { email: string }) => {
    const { data } = await execute(
        'POST',
        Api.DELETE_SECONDARY_EMAIL(),
        { data: { secondaryEmail: email } },
        true
    );
    return data;
};

export const registerSecondaryEmail = async ({ secondaryEmail, tag }) => {
    if (secondaryEmail) {
        const { data } = await execute(
            'POST',
            Api.REGISTER_SECONDARY_EMAIL(),
            {
                data: { secondaryEmail, tag },
            },
            true
        );
        return data;
    } else {
        return null;
    }
};

export const resendOtpSecondaryEmailRegistration = async ({ secondaryEmail }) => {
    if (secondaryEmail) {
        const { data } = await execute(
            'POST',
            Api.RESEND_OTP_SECONDARY_EMAIL_REGISTRATION(),
            {
                data: { secondaryEmail },
            },
            true
        );
        return data;
    } else {
        throw new Error('Secondary e-mail parameter is missing');
    }
};

export const verifySecondaryEmail = async ({ secondaryEmail, otp }) => {
    if (secondaryEmail) {
        const { data } = await execute(
            'POST',
            Api.VERIFY_SECONDARY_EMAIL(),
            {
                data: {
                    secondaryEmail,
                    otp,
                },
            },
            true
        );
        return data;
    } else {
        return null;
    }
};

export const fetchOnboardingSteps = async () => {
    const { data } = await execute('GET', Api.GET_ONBOARDING_STEPS(), {}, true);
    return data;
};

export const fetchPageContents = async (carrier?: string) => {
    const { data } = await execute(
        'GET',
        Api.GET_WEB_CONTENTS({ carrier: carrier || AppConfig.carrier })
    );
    return data;
};

export const updateFirstLogin = async (val: boolean) => {
    const { data } = await execute(
        'PUT',
        Api.UPDATE_FIRST_LOGIN(),
        { isFirstLogin: val },
        true
    );
    return data;
};
