export const Api = {
    LOGIN: `${process.env.REACT_APP_API_BASE_URL}/login/email`,
    VERIFY_OTP: `${process.env.REACT_APP_API_BASE_URL}/login/otp`,
    REGISTER: () => `${process.env.REACT_APP_API_BASE_URL}/register/primary/verify/email`,
    VERIFY_MOBILE_NUMBER: () =>
        `${process.env.REACT_APP_API_BASE_URL}/register/primary/verify/sms`,
    VERIFY_SMS_OTP: () =>
        `${process.env.REACT_APP_API_BASE_URL}/register/primary/verify/sms/otp`,
    VERIFY_REGISTRATION_EMAIL_OTP: () =>
        `${process.env.REACT_APP_API_BASE_URL}/register/primary/verify/email/otp`,
    GET_ACCOUNT_DETAILS: () => `${process.env.REACT_APP_API_BASE_URL}/user/details`,
    GET_ASSETS: ({ rescan }) => {
        if (rescan) {
            return `${process.env.REACT_APP_API_BASE_URL}/assets?rescan=true`;
        } else {
            return `${process.env.REACT_APP_API_BASE_URL}/assets`;
        }
    },
    GET_ASSESSMENTS_SUMMARY: () =>
        `${process.env.REACT_APP_API_BASE_URL}/assessment/summary`,
    GET_ASSESSMENTS_RESULT: () =>
        `${process.env.REACT_APP_API_BASE_URL}/assessment/result`,
    GET_ASSESSMENTS_GUIDE: (title: string) =>
        `${process.env.REACT_APP_API_BASE_URL}/assessment/guide?title=${title}`,
    RESOLVE_ASSESSMENT: () =>
        `${process.env.REACT_APP_API_BASE_URL}/assessment/guide/resolve`,
    GET_ASSESSMENT_QUESTIONS: `${process.env.REACT_APP_API_BASE_URL}/assessment/questions`,
    GET_ASSET_BY_TYPE: ({ asset }) =>
        `${process.env.REACT_APP_API_BASE_URL}/assets/${asset
            ?.replaceAll(' ', '-')
            .toLowerCase()}`,
    GET_ASSET_BY_TYPE_GROUPBY: ({ asset, groupby = 'value' }) =>
        `${process.env.REACT_APP_API_BASE_URL}/assets/${asset
            ?.replaceAll(' ', '-')
            .toLowerCase()}?groupBy=${groupby}`,
    UNMASKED_VALUE: ({ asset, documentId }) =>
        `${process.env.REACT_APP_API_BASE_URL}
        /assets/${asset?.toLowerCase()}/unmask?documentId=${documentId}`,
    MARKED_RESOLVE: ({ asset, documentId }) =>
        `${process.env.REACT_APP_API_BASE_URL}
        /assets/${asset.toLowerCase()}/resolve?documentId=${documentId}`,
    GET_BREACH_RESOLUTION_STEPS: (asset: string) =>
        `${process.env.REACT_APP_API_BASE_URL}/breach/resolutionSteps/${asset}`,
    GET_DASHBOARD_ARTICLES: () =>
        `${process.env.REACT_APP_API_BASE_URL}/articles/dashboard`,
    GET_ALL_SECURITY_ARTICLES: ({ topic }) =>
        `${process.env.REACT_APP_API_BASE_URL}/articles/security?topic=${topic}`,
    GET_SECURITY_ARTICLE: ({ slug }) =>
        `${process.env.REACT_APP_API_BASE_URL}/articles/security/content/${slug}`,
    GET_ALL_LATEST_ARTICLES: () =>
        `${process.env.REACT_APP_API_BASE_URL}/articles/latest`,
    GET_ARTICLE_FILTERS: () =>
        `${process.env.REACT_APP_API_BASE_URL}/articles/security/topics`,
    GET_PROFILE: () => `${process.env.REACT_APP_API_BASE_URL}/user/profile`,
    UPDATE_PROFILE: () => `${process.env.REACT_APP_API_BASE_URL}/user/profile`,
    DELETE_SECONDARY_EMAIL: () => `${process.env.REACT_APP_API_BASE_URL}/user/remove`,
    REGISTER_SECONDARY_EMAIL: () =>
        `${process.env.REACT_APP_API_BASE_URL}/register/secondary`,
    VERIFY_SECONDARY_EMAIL: () =>
        `${process.env.REACT_APP_API_BASE_URL}/register/secondary/verify`,
    RESEND_OTP_SECONDARY_EMAIL_REGISTRATION: () =>
        `${process.env.REACT_APP_API_BASE_URL}/register/secondary/resend`,
    LOGOUT: () => `${process.env.REACT_APP_API_BASE_URL}/logout`,
    GET_ONBOARDING_STEPS: () => `${process.env.REACT_APP_API_BASE_URL}/onboarding`,
    GET_WEB_CONTENTS: ({ carrier }) =>
        `${process.env.REACT_APP_API_BASE_URL}/content?carrier=${carrier}`,
    UPDATE_FIRST_LOGIN: () => `${process.env.REACT_APP_API_BASE_URL}/user/isFirstLogin`,
};
